import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import loading from 'images/icons/three-dots.svg'

class Search extends Component {
  constructor(props) {
    super(props)

    this.state = {
      setRadio: true,
      activeType: props.query ? props.query[0] : [],
      byParent: props.query ? props.query[1] : {},
      disabled: props.query ? !(props.query[0].length + Object.keys(props.query[1]).length === props.categories.length) : true
    }
  }

  // componentDidMount() {
  //   console.log('componentDidMount')
  // }

  filterTypes = (c) => {
    // if (this.state.activeType.length >= 3 && t.allways) {
    //   return true
    // }
    // console.log('c', c.id, t)

    // if (c === 3) {
    //   return false
    // }

    return t => {
      // console.log('c', c.id, t)
      if (c.id === 4 && this.state.activeType.length < 3) {
        return false
      }

      if (c.id === 5 && Object.keys(this.state.byParent).length >= 1) {
        return true
      }

      return this.state.activeType.indexOf(t.parent) !== -1 || t.parent === null
    }
  }

  isChecked = (t, c) => {
    if (c.id === 5 && Object.keys(this.state.byParent).length === 1) {
      document.getElementsByName('c5').forEach(e => e.checked = false)
      return false
    }

    return this.state.setRadio && this.props.query && (this.props.query[0].indexOf(t.id) !== -1 || this.props.query[1][c.id] === t.id)
  }

  handleChange = (t, c) => (event) => {
    // let activeType = t.allways ? this.state.activeType : t.ancestors
    let activeType = c.id >= 5 ? this.state.activeType : t.ancestors
    let byParent = this.state.byParent

    // if (c.id === 4) {
    //   byParent = {}
    //   byParent[c.id] = t.id
    // } else if (c.id === 5) {
    //   byParent[c.id] = t.id
    // } else {
    //   byParent = {}
    // }

    byParent = {}

    this.setState({
      byParent,
      activeType,
      setRadio: false,
      disabled: activeType.length + Object.keys(byParent).length === this.props.categories.length ? false : true
    })
  }

  render() {
    return (
      <div className="row search">
        {/*<h2>Wyszukiwarka</h2>*/}
        {this.props.categories.map(c =>
          <div key={c.id} className={`col-md-${c.id == 1 ? '3' : '2'}`}>
            <div className={`group ${c.types.filter(this.filterTypes(c)).length === 0 ? 'empty' : 'filled'}`}>
              <h4>{c.name}</h4>
              {c.types.filter(this.filterTypes(c)).length !== 0 && <ul>
                {c.types.filter(this.filterTypes(c)).map(t =>
                  <li key={t.id}>
                    <label>
                      <input type="radio" name={`c${c.id}`} value={t.id} defaultChecked={this.isChecked(t, c)} onChange={this.handleChange(t, c)} />
                      {t.name}
                    </label>
                  </li>
                )}
                {/* {c.types.filter(this.filterTypes(c)).length === 0 &&
                  <li className="loading"><i className ="fa fa-ellipsis-h"></i></li>
                } */}
              </ul> }
            </div>
          </div>
        )}

        <div className="col-md-1 actions">
          <form method="GET" action={this.props.url}>
            <input type="hidden" name="q" value={JSON.stringify([this.state.activeType, this.state.byParent])} />
            <button className="btn btn-primary" disabled={this.state.disabled}>{this.props.btn}</button>
          </form>
        </div>
      </div>
    )
  }
}

Search.defaultProps = {
  categories: []
}

Search.propTypes = {
  categories: PropTypes.array
}

window.renderSearch = (el) => {
  const url = el.getAttribute('data-url')
  const btn = el.getAttribute('data-btn')
  const categories = JSON.parse(el.getAttribute('data-categories'))
  const query = JSON.parse(el.getAttribute('data-query'))

  ReactDOM.render(
    <Search url={url} btn={btn} categories={categories} query={query} />,
    el
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('search')

  if (el) {
    window.renderSearch(el)
  }
})
